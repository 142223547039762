<template>
  <v-list-item
    class="px-4"
    :to="nodetails ? to : null"
    @[ev]="click"
    v-bind="$attrs"
    :value="value"
  >
    <v-list-item-avatar>
      <PortraitImage :value="value" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title><PersonName :value="value" ext /> </v-list-item-title>
      <v-list-item-subtitle v-if="value"
        >{{ subtitle }}
        <PersonFlags :value="value" />
      </v-list-item-subtitle>
      <slot />
    </v-list-item-content>
    <v-list-item-action v-if="!nodetails && clickable">
      <v-btn :to="to" @click.stop icon
        ><v-icon> mdi-dots-horizontal </v-icon></v-btn
      >
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import PortraitImage from "common/components/PortraitImage.vue";
import PersonFlags from "common/components/PersonFlags.vue";
import PersonName from "common/components/PersonName.vue";
import { personSubtitle } from "common/utils/people.js";

export default {
  name: "Person",
  components: { PersonFlags, PersonName, PortraitImage },
  props: {
    value: null,
    clickable: Boolean,
    nolink: Boolean,
    nodetails: Boolean,
  },
  computed: {
    ev() {
      return this.clickable && this.nodetails ? "click" : null;
    },
    to() {
      if (this.nolink || this.nodetail || !this.value) {
        return null;
      }
      return this.clickable ? "/people/" + this.value.id : null;
    },
    subtitle() {
      return personSubtitle(this.value);
    },
  },
  methods: {
    click() {
      this.$emit("click", this.value);
    },
  },
};
</script>
