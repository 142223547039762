<template>
  <v-dialog
    max-width="500"
    width="100%"
    :value="true"
    @click:outside="!edit && close"
    scrollable
    @keydown.esc="close"
    :persistent="edit"
  >
    <v-card>
      <v-progress-linear
        indeterminate
        color="error"
        v-if="loading"
      ></v-progress-linear>
      <v-card-title class="pa-0">
        <v-toolbar :dark="!edit" class="mb-2" :color="edit ? 'error' : ''">
          <v-btn icon>
            <v-icon>mdi-vector-triangle</v-icon>
          </v-btn>
          <v-toolbar-title>{{ group.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="pa-0" v-if="group && group.type">
        <v-list v-if="edit">
          <v-list-item>
            <v-list-item-avatar>
              <PortraitImage :value="person" />
            </v-list-item-avatar>
            <PersonInput clearable v-model="person" hidedetails />
            <v-list-item-action>
              <v-btn icon :disabled="!person" @click="add"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <v-subheader>{{ group.type.description }}</v-subheader>
          <v-list-item-group v-model="selected" multiple>
            <v-list-item
              v-for="member in group.members.concat(this.newMembers)"
              :key="member.id"
              :value="member.id"
            >
              <template v-slot:default="{ active }">
                <v-list-item-avatar>
                  <PortraitImage :value="member" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    ><PersonName :value="member" code
                  /></v-list-item-title>
                  <v-list-item-subtitle>{{
                    personSubtitle(member)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-list v-else>
          <v-subheader>{{ group.type.description }}</v-subheader>
          <PersonItem
            v-for="member in group.members"
            :key="member.id"
            :value="member"
          />
        </v-list>

        <v-list v-if="!loading && group.members.length < 1">
          <v-list-item>
            <v-alert width="100%" type="warning" dark>keine Mitglieder</v-alert>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider v-if="edit"></v-divider>
      <v-card-actions v-if="edit">
        <v-spacer />
        <v-btn text @click="save" color="success"
          ><v-icon left>mdi-content-save</v-icon> Speichern</v-btn
        >
        <v-btn text @click="close"
          ><v-icon left>mdi-close</v-icon> Abbrechen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sortPeople, personSubtitle } from "common/utils/people.js";
import PersonItem from "@/components/PersonItem";
import PersonInput from "common/components/PersonInput.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";

export default {
  name: "GroupsDetails",
  components: { PersonItem, PersonInput, PersonName, PortraitImage },
  props: ["id"],
  data() {
    return {
      newMembers: [],
      person: {},
      group: {},
      loading: true,
      selected: [],
    };
  },
  computed: {
    edit() {
      return this.group && this.group.type && this.group.type.code === "KINET";
    },
  },
  methods: {
    personSubtitle,
    close() {
      this.$router.back();
    },
    async save() {
      await this.apiPut({
        resource: "account/group",
        data: { id: this.group.id, members: this.selected },
      });
      this.$router.back();
    },
    add() {
      this.newMembers.push(this.person);
      this.selected.push(this.person.id);
      this.person = {};
    },
  },
  async created() {
    this.loading = true;
    const data = await this.apiGet({ resource: "account/group", id: this.id });
    data.members = sortPeople(data.members);
    this.group = data;
    this.selected = this.group.members.map((item) => item.id);
    this.loading = false;
  },
};
</script>
